const $form = document.querySelector('form#contato')

const setLoading = status => {
  $form.classList.toggle('loading', status)
  console.log($form.classList)
  Object.values(document.querySelectorAll('form#contato [name]'))
    .forEach(input => input[status ? 'setAttribute' : 'removeAttribute']('disabled', true))
}

$form.addEventListener('submit', (e) => {
  e.preventDefault()
  const data = {
    name: document.querySelector('#contato [name=name]').value,
    responsible: document.querySelector('#contato [name=responsible]').value,
    phone: document.querySelector('#contato [name=phone]').value,
    email: document.querySelector('#contato [name=email]').value,
    social: document.querySelector('#contato [name=social]').value,
    subject: 'Contato através do site',
    message: document.querySelector('#contato [name=message]').value,
  }
  if(data.name == '' || data.responsible == '' || data.phone == '' || data.message == '')
  {
    alert('Todos os campos são obrigatórios.')
    return false
  }
  if(validateEmail() === false)
  {
    alert('Por favor insira um endereço de e-mail válido.')
    return false
  }

  $form.classList.value = 'form'
  setLoading(true)
  fetch('https://us-central1-emiolo-static.cloudfunctions.net/sendMail', {
    method: 'POST',
    body: JSON.stringify(data)
  })
    .then(response => {
      if (!response.ok) throw new Error('Something wrong is not right')
      setLoading(false)
      $form.classList.toggle('success', true)
    })
    .catch(() => {
      setLoading(false)
      $form.classList.toggle('fail', true)
    })
  return false
})

function validateEmail () {
  const $email = document.querySelector('#contato [name=email]').value
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test($email)
}
